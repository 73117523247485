/* eslint-disable class-methods-use-this,react/jsx-no-bind,react/jsx-filename-extension */
/* global TES IS_PRODUCTION */
import React, { Component as ReactComponent } from 'react';
import cookies from 'browser-cookies';
import {
  trackDisplay, trackAccept, trackClose, trackSubmit, trackClick,
} from '../utils/analytics';

const trace = (str) => {
  if (IS_PRODUCTION) return;
  console.log(str);
};

const trackableComponentWrapper = ({
  campaignId,
  slotName,
  componentName,
  doNotTrack,
}) => (Component) => {
  const { handleOwnDisplay } = Component;

  class WrappedTrackableComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.state = { accepted: false };
    }

    handleDisplay(payload) {
      if (doNotTrack) {
        return trace('tracking disabled, otherwise would fire: DISPLAY');
      }
      trackDisplay(campaignId, slotName, componentName, payload);
    }

    async handleAccept(payload, { ack = true, close } = {}) {
      if (close) this.props.handleBannerClose();
      if (ack) await TES.marketing.ackSlot(campaignId);
      if (doNotTrack) {
        return trace(`tracking disabled, otherwise would fire: ACCEPT, with payload: ${payload}`);
      }
      this.setState({ accepted: true });
      trackAccept(campaignId, slotName, componentName, payload);
    }

    async handleClose(payload) {
      this.props.handleBannerClose();
      await TES.marketing.ackSlot(campaignId);
      if (doNotTrack) {
        return trace('tracking disabled, otherwise would fire: CLOSE');
      }
      trackClose(campaignId, slotName, componentName, payload);
    }

    handleSubmit(payload) {
      if (doNotTrack) {
        return trace(`tracking disabled, otherwise would fire: SUBMIT, with payload: ${payload}`);
      }
      trackSubmit(campaignId, slotName, componentName, payload);
    }

    handleClick(payload) {
      if (doNotTrack) {
        return trace('tracking disabled, otherwise would fire: CLICK');
      }
      trackClick(campaignId, slotName, componentName, payload);
    }

    saveCampaignState(payload, expireIn = 60) {
      const expirationDate = new Date();
      expirationDate.setSeconds(expirationDate.getSeconds() + expireIn);
      cookies.set(`MML-campaign-state-${campaignId}`, JSON.stringify(payload), { expires: expirationDate });
    }

    loadCampaignState() {
      const str = cookies.get(`MML-campaign-state-${campaignId}`);
      if (!str) {
        return;
      }
      return JSON.parse(str);
    }

    deleteCampaignState() {
      cookies.erase(`MML-campaign-state-${campaignId}`);
    }

    componentDidMount() {
      !handleOwnDisplay && this.handleDisplay();
    }

    componentWillUnmount() {
      if (!doNotTrack && !this.state.accepted) {
        // track ignore
      }
    }

    render() {
      return (
        <Component
          handleAccept={this.handleAccept.bind(this)}
          handleClose={this.handleClose.bind(this)}
          handleDisplay={this.handleDisplay.bind(this)}
          handleSubmit={this.handleSubmit.bind(this)}
          campaignId={campaignId}
          slotName={slotName}
          componentName={componentName}
          handleClick={this.handleClick.bind(this)}
          saveCampaignState={this.saveCampaignState.bind(this)}
          loadCampaignState={this.loadCampaignState.bind(this)}
          deleteCampaignState={this.deleteCampaignState.bind(this)}
          {...this.props}
        />
      );
    }
  }

  return WrappedTrackableComponent;
};

export default trackableComponentWrapper;
