/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.onError(error, info);
  }

  render() {
    const inlineStyle = {
      margin: 0,
      padding: 0,
      borderStyle: 'none',
    };

    if (this.state.hasError) {
      return <div style={inlineStyle} dangerouslySetInnerHTML={{ __html: this.props.placeHolderContent }} />;
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  onError: PropTypes.func.isRequired,
  placeHolderContent: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  placeHolderContent: '',
};

export default function wrapComponentWithErrorBoundary({ onError, placeHolderContent }) {
  // eslint-disable-next-line no-shadow
  return Component => props => (
    <ErrorBoundary onError={onError} placeHolderContent={placeHolderContent}>
      <Component {...props} />
    </ErrorBoundary>
  );
}
