/* global fetch */
import cookies from 'browser-cookies';
import deferUntilOnline from 'defer-until-online';

const serviceMarketingPublicRoot = '/api/marketing';
const checkCampaignsEndpoint = `${serviceMarketingPublicRoot}/multi-check`;
const ackCampaignEndpoint = `${serviceMarketingPublicRoot}/ack`;

const isDebug = () => /martech-debug/.test(document.location.search);

export const checkCampaigns = async (payload = {}, retryCount = 0) => {
  const uniqueId = cookies.get('__tesu');
  if (!uniqueId) {
    // this will wait 60 seconds for __tesu cookie to be set before throwing an error

    if (retryCount > 60) throw new Error('Cannot find uniqueid, no __tesu cookie after 60 seconds (checkCampaigns)');
    await new Promise(r => setTimeout(r, 1000));
    return checkCampaigns(payload, retryCount + 1);
  }

  let response;
  try {
    const payloadCopy = JSON.parse(JSON.stringify(payload));
    payloadCopy.debug = isDebug();
    response = await fetch(checkCampaignsEndpoint, {
      body: JSON.stringify(payloadCopy),
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    throw new Error(`Checkcampaigns fetch Error: ${e.message}`);
  }

  if (response.ok) {
    return response.json();
  }

  throw new Error(`checkCampaigns: response not ok: ${response.status}`);
};

export const ackCampaign = deferUntilOnline()(async (campaignId) => {
  const uniqueId = cookies.get('__tesu');
  if (!uniqueId) throw new Error('Cannot find uniqueid (ackCampaign)');

  const ackUrl = `${ackCampaignEndpoint}/${campaignId}`;
  let response;
  try {
    response = await fetch(ackUrl, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    throw new Error(`AckCampaign Fetch Error: ${e.message}`);
  }

  if (response.ok) {
    return;
  }

  throw new Error(`ackCampaign: response not ok: ${response.status}`);
});
