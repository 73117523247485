import { createElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import compose from 'async-deco/es2015/utils/compose';
import airbrake from './airbrake';
import trackableComponentWrapper from './decorators/trackable-component';
import SlotSwapper from './decorators/slot-swapper';
import wrapComponentWithErrorBoundary from './decorators/error-boundary';
import { getNameAndURL, loadComponent } from './utils/external-component';

const LOAD_COMPONENT_TIMEOUT = 40000; // 40 seconds
const SLOT_CAMPAIGNID_DATA_ATTRIBUTE_NAME = 'data-x-marketing-rendered-campaign';

function alreadyRenderedCampaign(slotElement, campaignId) {
  if (!slotElement) return true;
  const previousCampaignId = slotElement.getAttribute(SLOT_CAMPAIGNID_DATA_ATTRIBUTE_NAME);
  return String(campaignId) === previousCampaignId;
}

function markRenderedCampaign(slotElement, campaignId) {
  if (slotElement) slotElement.setAttribute(SLOT_CAMPAIGNID_DATA_ATTRIBUTE_NAME, campaignId);
}

function removeRenderedCampaign(slotElement) {
  if (slotElement) slotElement.removeAttribute(SLOT_CAMPAIGNID_DATA_ATTRIBUTE_NAME);
}

export default class CampaignRenderer {
  constructor({ rootNode = global.document.body, load = loadComponent } = {}) {
    this.rootNode = rootNode;
    this.loadComponent = load;
  }

  // eslint-disable-next-line class-methods-use-this
  unRenderCampaign(slotElement) {
    unmountComponentAtNode(slotElement);
    removeRenderedCampaign(slotElement);
  }

  async renderCampaign({
    slotElement,
    slotName,
    componentName,
    reactComponentName,
    renderProps,
    campaignId,
    doNotTrack,
    htmlContent,
    usePreview,
  }) {
    try {
      if (alreadyRenderedCampaign(slotElement, campaignId)) {
        return;
      }

      let loadedComponent;

      if (!htmlContent) {
        const { name, url } = await getNameAndURL(reactComponentName, window.location.hostname);
        loadedComponent = await this.loadComponent({ name, url, timeout: LOAD_COMPONENT_TIMEOUT });
        if (!loadedComponent) {
          throw new Error('The component is undefined or null');
        }

        if (usePreview && loadedComponent.CustomPreview) { // allow to create a custom preview
          loadedComponent = loadedComponent.CustomPreview;
        }

        if (!this.isDescendantOfRootNode(slotElement)) {
          // slotElement is not part of target subtree anymore
          return;
        }
      } else {
        loadedComponent = () => createElement('div', { dangerouslySetInnerHTML: { __html: htmlContent } });
      }

      const airbrakeLogError = (error, info) => airbrake.notify({ error, params: { component: reactComponentName, info } });

      const componentDecorator = compose(
        SlotSwapper(), // third
        wrapComponentWithErrorBoundary({ onError: airbrakeLogError, placeHolderContent: slotElement.innerHTML }), // second
        trackableComponentWrapper({
          campaignId, slotName, componentName, doNotTrack,
        }), // first
      );

      const marketingBanner = createElement(componentDecorator(loadedComponent), renderProps);
      unmountComponentAtNode(slotElement); // this remove a react component mounted here
      // eslint-disable-next-line no-param-reassign
      slotElement.innerHTML = ''; // remove the placeholder

      render(marketingBanner, slotElement);
      markRenderedCampaign(slotElement, campaignId);
    } catch (error) {
      // handle unexpected errors
      console.log(error);
      airbrake.notify({ error, params: { campaignId } });
    }
  }

  isDescendantOfRootNode(node) {
    return this.rootNode.contains(node);
  }
}
