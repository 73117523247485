const ROUTE_CHANGE_INTERVAL_FOCUSED = 1000;

export default class UrlChangeWatcher {
  constructor({
    win = window,
    onUrlChange = () => {
    },
    interval = ROUTE_CHANGE_INTERVAL_FOCUSED,
  }) {
    this.win = win;
    this.interval = interval;
    this.onUrlChange = onUrlChange;
    this.previousUrl = this.getUrl();
  }

  startObserving() {
    this.intervalId = setInterval(() => this.check(), this.interval);
  }

  stopObserving() {
    clearInterval(this.intervalId);
  }

  check() {
    if (this.previousUrl !== this.getUrl()) {
      this.previousUrl = this.getUrl();
      this.onUrlChange(this.previousUrl);
    }
  }

  getUrl() {
    const { location: { pathname, search } } = this.win;
    return `${pathname}${search}`;
  }
}
