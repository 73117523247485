/* eslint-env browser */
const assertTargetGetElem = (targetIdOrElem) => {
  const target = typeof targetIdOrElem === 'string'
    ? document.getElementById(targetIdOrElem)
    : targetIdOrElem;
  if (!(target instanceof Node)) {
    throw new Error('Invalid target: argument must be an element or an ID string');
  }
  return target;
};

export default assertTargetGetElem;
