import SlotObserver from './slot-observer';
import UrlChangeWatcher from './url-change-watcher';
import assertTargetGetElem from './utils/assert-target-get-element';
import airbrake from './airbrake';
import CampaignRenderer from './campaign-renderer';
import { getMetadata } from './utils/analytics';
import { checkCampaigns, ackCampaign } from './api-client';

export class MarketingLoaderEngine {
  constructor({
    doNotTrack = false,
    slotObserver,
    urlChangeWatcher,
    campaignRenderer,
    checkCampaigns: checkCampaignsOverride,
  }) {
    this.doNotTrack = doNotTrack;
    this.slotObserver = slotObserver || new SlotObserver({
      onSlotsMount: slots => this.mountSlots(slots),
      onSlotsUnMount: slots => this.unMountSlots(slots),
      onSlotsUpdate: slots => this.mountSlots(slots),
    });
    this.urlChangeWatcher = urlChangeWatcher || new UrlChangeWatcher({ onUrlChange: this.onUrlChange.bind(this) });
    this.campaignRenderer = campaignRenderer || new CampaignRenderer();
    this.checkCampaigns = checkCampaignsOverride || checkCampaigns;
  }

  start() {
    this.slotObserver.startObserving();
    this.urlChangeWatcher.startObserving();
  }

  stop() {
    this.slotObserver.stopObserving();
    this.urlChangeWatcher.stopObserving();
  }

  async unMountSlots(slots) {
    const slotElements = slots.map(slot => assertTargetGetElem(slot.node));
    slotElements.forEach(slotElement => this.campaignRenderer.unRenderCampaign(slotElement));
  }

  async mountSlots(slots = []) {
    if (slots.length === 0) return;
    try {
      const slotElements = slots
        .reduce((obj, slot) => {
          // eslint-disable-next-line no-param-reassign
          obj[slot.slotName] = assertTargetGetElem(slot.node);
          return obj;
        }, {});
      const slotNames = Object.keys(slotElements);
      const slotsData = await this.checkCampaigns({ ...getMetadata(), slotNames });

      if (!Array.isArray(slotsData.result)) throw new Error('Checking campaign: malformed response');

      const slotRenderedLookup = {};

      for (let i = 0; i < slotsData.result.length; i++) {
        const {
          reactComponentName,
          componentName,
          renderProps,
          id: campaignId,
          htmlContent,
          slotName,
        } = slotsData.result[i];
        const slotElement = slotElements[slotName];
        if (!slotElement) throw new Error(`Checking campaign: error selecting the slot ${slotName}`);

        if (!campaignId) {
          // no relevant campaign
          // eslint-disable-next-line no-continue
          continue;
        }
        slotRenderedLookup[slotName] = true;
        this.campaignRenderer.renderCampaign({
          slotElement,
          slotName,
          componentName,
          reactComponentName,
          renderProps,
          campaignId,
          htmlContent: htmlContent ? decodeURIComponent(htmlContent) : null,
        });
      }
      // unrender
      for (let i = 0; i < slotNames.length; i++) {
        const slotName = slotNames[i];
        if (!slotRenderedLookup[slotName]) {
          this.campaignRenderer.unRenderCampaign(slotElements[slotName]);
        }
      }
      if (window.performance && window.performance.mark) {
        window.performance.mark('tes-module-marketing-first-render');
      }
    } catch (error) {
      console.log(error);
      airbrake.notify(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async ackSlot(campaignId) {
    try {
      await ackCampaign(campaignId);
    } catch (error) {
      console.log(error);
      airbrake.notify(error);
    }
  }

  // used for debugging
  renderCampaign({
    targetIdOrElem, reactComponentName, renderProps, doNotTrack = false, usePreview,
  }) {
    const slotElement = assertTargetGetElem(targetIdOrElem);
    this.campaignRenderer.unRenderCampaign(slotElement);
    this.campaignRenderer.renderCampaign({
      slotElement,
      reactComponentName,
      renderProps,
      doNotTrack,
      usePreview,
    });
  }

  async onUrlChange() {
    await this.slotObserver.findAndUpdateSlots([document.body]);
  }
}

let engineInstance;

const init = (debug) => {
  engineInstance = new MarketingLoaderEngine({ doNotTrack: debug });
  return engineInstance;
};

export default init;
