/* eslint-disable no-underscore-dangle */
/* global VERSION BUILD IS_PRODUCTION */
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import initEngine from './engine';

const globalName = 'marketing';

const exportGlobal = (lib, name = globalName) => {
  if (!window.TES) {
    window.TES = {};
  }
  if (!window.TES[name]) {
    window.TES[name] = lib;
  }
};

const isMarketingDisabled = () => {
  try {
    return !!window.TES.marketingDisabled;
  } catch (e) {
    return false;
  }
};

function init() {
  const _engine = initEngine(!IS_PRODUCTION);
  exportGlobal(_engine);

  if (!IS_PRODUCTION) {
    console.log(`module-marketing-loader initialized. version: ${VERSION} build: ${BUILD}`);
  }

  if (window.performance && window.performance.mark) {
    window.performance.mark('tes-module-marketing-ready');
  }

  if (isMarketingDisabled()) return;
  _engine.start();
}

init();
