import airbrake from '../airbrake';

const getMz = () => (window.TES ? window.TES.mz : undefined);
const getMzEvents = () => (getMz() ? getMz().events : undefined);

/**
 * get metadata provided by mz
 */
export const getMetadata = () => {
  const metadata = {};
  const tesGlobal = window.TES;
  if (tesGlobal) {
    const { pageMetadata, userMetadata } = tesGlobal;
    metadata.mz = { pageMetadata, userMetadata };
  } else {
    console.log('MML: Unable to find window.TES');
    airbrake.notify(new Error('Unable to find window.TES'));
  }
  // add additional data if needed
  return metadata;
};

const trackEvent = (eventName, campaignId, slotName, componentName, props = {}) => {
  const mz = getMz();
  const mze = getMzEvents();
  const { payload = {}, ...otherProps } = props;

  if (mz && mze) {
    mz.emit(mze[eventName], {
      module: {
        type: 'marketing-banners',
        name: campaignId,
      },
      payload: { ...payload, slotName, componentName },
      ...otherProps,
    });
  } else {
    console.log(`MML: Tried to track ${eventName}, but couldn't find mz!`);
    airbrake.notify(new Error('Missing mz'));
  }
};

export const trackDisplay = trackEvent.bind(null, 'DISPLAY');
export const trackClose = trackEvent.bind(null, 'CLOSE');
export const trackAccept = trackEvent.bind(null, 'ACCEPT');
export const trackSubmit = trackEvent.bind(null, 'SUBMIT');
export const trackClick = trackEvent.bind(null, 'CLICK');
