/* eslint-disable */
import React, { Component as ReactComponent } from 'react';

export default () => Component => class MarketingBanner extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hiding: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ visible: true }), 100);
  }

  handleTransitionEnd() {
    this.setState({ showBanner: false });
  }

  handleBannerClose() {
    this.setState({ hiding: true, visible: false });
  }

  render() {
    const { hiding, visible } = this.state;
    return (
      <Component
        hiding={hiding}
        visible={visible}
        handleTransitionEnd={this.handleTransitionEnd.bind(this)}
        handleBannerClose={this.handleBannerClose.bind(this)}
        {...this.props}
      />
    );
  }
};
