/* eslint-env browser */
const SLOT_ATTRIBUTE_NAME = 'data-x-marketing-slot';
const SLOT_MOUNTED_ATTRIBUTE_NAME = 'data-x-marketing-slot-mounted';
const DEFAULT_CHECK_GRANULARITY = 1000;
const SLOT_NOT_YET_MOUNTED_CSS_SELECTOR = `[${SLOT_ATTRIBUTE_NAME}]:not([${SLOT_MOUNTED_ATTRIBUTE_NAME}])`;
const SLOT_MOUNTED_CSS_SELECTOR = `[${SLOT_ATTRIBUTE_NAME}][${SLOT_MOUNTED_ATTRIBUTE_NAME}]`;
const GLOBAL_SLOT_NAMES = ['Global Campaign Slot', 'User Onboarding Slot'];

const toArray = arrayLike => (arrayLike ? Array.prototype.slice.call(arrayLike, 0) : []);

// matches polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

const markSlotAsMounted = node => node.setAttribute(SLOT_MOUNTED_ATTRIBUTE_NAME, '');
const markSlotAsUnMounted = node => node.removeAttribute(SLOT_MOUNTED_ATTRIBUTE_NAME);

const getAllSlots = (allNodes, selector) => allNodes
  .map((node) => {
    if (node.nodeType !== Node.ELEMENT_NODE) return [];
    const mountedSlots = node.matches(selector) ? [node] : toArray(node.querySelectorAll(selector));
    return mountedSlots;
  })
  .reduce((acc, value) => acc.concat(value), []);

/**
 * Internal class.
 * Watches a subtree of nodes for newly inserted slots,
 * when it finds one then calls the given callback.
 */
export default class SlotObserver {
  constructor({
    onSlotsMount,
    onSlotsUnMount = () => {},
    onSlotsUpdate = () => {},
    checkGranularity = DEFAULT_CHECK_GRANULARITY,
    rootNode = global.document.body,
    useMutationObserver = !!window.MutationObserver,
  }) {
    if (typeof onSlotsMount !== 'function') {
      throw new Error('SlotObserver requires onSlotsMount to be a function');
    }
    this.onSlotsMount = onSlotsMount;
    this.onSlotsUnMount = onSlotsUnMount;
    this.onSlotsUpdate = onSlotsUpdate;
    this.checkGranularity = checkGranularity;
    this.rootNode = rootNode;
    this.useMutationObserver = useMutationObserver;

    GLOBAL_SLOT_NAMES.forEach((GLOBAL_SLOT_NAME) => {
      // We set a Global Campaign Slot that is inserted on every page
      const dynamicSlot = global.document.createElement('div');
      dynamicSlot.setAttribute('data-x-marketing-slot', GLOBAL_SLOT_NAME);
      this.rootNode.insertBefore(dynamicSlot, this.rootNode.firstChild);
    });

    if (this.useMutationObserver) {
      this.mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          this.findAndMountSlots(toArray(mutation.addedNodes));
          this.findAndUnMountSlots(toArray(mutation.removedNodes));
        });
      });
    }
  }

  stopObserving() {
    if (this.useMutationObserver) {
      this.mutationObserver.disconnect();
    } else {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  startObserving() {
    this.stopObserving();
    this.findAndMountSlots([this.rootNode]);

    if (this.useMutationObserver) {
      this.mutationObserver.observe(document.body, {
        childList: true,
        subtree: true,
      });
    } else {
      this.intervalId = setInterval(() => this.findAndMountSlots([this.rootNode]), this.checkGranularity);
    }
  }

  findAndMountSlots(allNodes = []) {
    const args = getAllSlots(allNodes, SLOT_NOT_YET_MOUNTED_CSS_SELECTOR)
      .map((node) => {
        const slotName = node.getAttribute(SLOT_ATTRIBUTE_NAME);
        markSlotAsMounted(node);
        return { node, slotName };
      })
      .filter(arg => arg.slotName);
    return this.onSlotsMount(args);
  }

  findAndUnMountSlots(allNodes = []) {
    const args = getAllSlots(allNodes, SLOT_MOUNTED_CSS_SELECTOR)
      .map((node) => {
        const slotName = node.getAttribute(SLOT_ATTRIBUTE_NAME);
        markSlotAsUnMounted(node);
        return { node, slotName };
      })
      .filter(arg => arg.slotName);
    return this.onSlotsUnMount(args);
  }

  findAndUpdateSlots(allNodes = []) {
    const args = getAllSlots(allNodes, SLOT_MOUNTED_CSS_SELECTOR)
      .map((node) => {
        const slotName = node.getAttribute(SLOT_ATTRIBUTE_NAME);
        markSlotAsMounted(node);
        return { node, slotName };
      })
      .filter(arg => arg.slotName);
    return this.onSlotsUpdate(args);
  }
}
