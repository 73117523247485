import airbrakeJs from 'airbrake-mini-client';
import { getEnvironment } from './utils/environment';

const PROJECT_ID = 196643;
const PROJECT_KEY = '5973ba185fd3bdb2daaa1b041b4be3cc';

const airbrake = airbrakeJs({
  projectId: PROJECT_ID,
  projectKey: PROJECT_KEY,
  environment: getEnvironment(window.location.hostname),
});

/*       Airbrake logging is restricted for 10% of users only       */
/*    as this code is used heavily on clients all around the site   */
/*          https://github.com/tes/enhance-team/issues/265          */
/*          https://github.com/tes/enhance-team/issues/191          */
/* */
/* */ let filterAll = Math.random() > 0.1; // should be set to false to turn on for all
/* */

const UAExcluded = [/BingPreview/];
const filterByUA = (ua) => {
  for (let i = 0; i < UAExcluded.length; i++) {
    if (UAExcluded[i].test(ua)) return true;
  }
  return false;
};

airbrake.addFilter((notice) => {
  if (filterAll) return;
  const { context } = notice;
  if (!context) return null;
  if (context.url && context.url.indexOf('file') === 0) return null;
  if (context.environment === 'unknown' || context.environment === 'local') return null;
  if (filterByUA(context.userAgent)) return null;
  return notice;
});

// I am switching off airbrake when the browser is unloading
window.addEventListener('unload', () => {
  filterAll = true;
});

export default airbrake;
