const envs = [
  { regexp: /\.tes\.com$/, name: 'production' },
  { regexp: /local\.tes-dev\.com$/, name: 'local' },
  { regexp: /\.tes-dev\.com$/, name: 'development' },
  { regexp: /\.tes-stage\.com$/, name: 'staging' },
  { regexp: /\.tes-local\.com$/, name: 'local' },
];

const envsToUrl = {
  production: 'https://www.tes.com',
  development: 'https://www.tes-dev.com',
  staging: 'https://www.tes-stage.com',
  local: 'https://www.tes-local.com',
  unknown: 'https://www.tes.com',
};

export function getEnvironment(hostname) {
  for (let i = 0; i < envs.length; i++) {
    const { regexp, name } = envs[i];
    if (regexp.test(hostname)) return name;
  }
  return 'unknown';
}

export function getAbsoluteUrl(url, hostname) {
  if (url.indexOf('/') !== 0) {
    return url;
  }
  const environment = getEnvironment(hostname);
  return `${envsToUrl[environment]}${url}`;
}
