import asyncDefine from 'async-define';
import deferUntilOnline from 'defer-until-online';
import compose from 'async-deco/es2015/utils/compose';
import retry from 'async-deco/es2015/retry';
import { getAbsoluteUrl } from './environment';

const doOnce = (func) => {
  let result;
  return () => {
    if (!result) {
      result = func();
    }
    return result;
  };
};

const retryAndMemoize = compose(
  doOnce,
  retry({ times: 10, interval: 10 }),
);

const getMMLScriptTag = retryAndMemoize(() => {
  const me = document.querySelector('script[src$="marketing-loader.js"]');
  if (me) {
    return Promise.resolve(me);
  }
  return Promise.reject(new Error('Unable to find marketing-loader script'));
});

export async function getCurrentPath() {
  const me = await getMMLScriptTag();
  const currentBundle = me.src;
  return currentBundle.split('/').slice(0, -1).join('/');
}

export function isJSURL(str) {
  const trimmed = str.trim().toLowerCase();
  return trimmed.substring(trimmed.length - 3, trimmed.length) === '.js';
}

export function getNameFromURL(url) {
  const filename = url.trim().split('/').slice(-1)[0];
  return filename.substring(0, filename.length - 3);
}

export async function getURLFromName(name) {
  const currentPath = await getCurrentPath();
  return `${currentPath}/${name.trim()}.js`;
}

export async function getNameAndURL(nameOrURL, hostname) {
  if (isJSURL(nameOrURL)) {
    return {
      name: getNameFromURL(nameOrURL),
      url: getAbsoluteUrl(nameOrURL, hostname),
    };
  }
  return {
    name: nameOrURL,
    url: await getURLFromName(nameOrURL),
  };
}

export function loadURL(url, ms) {
  return new Promise((resolve, reject) => {
    try {
      let scriptState = '';
      let script = document.querySelector(`script[src="${url}"]`);
      if (script) {
        return;
      }
      script = document.createElement('script');
      script.onerror = () => {
        reject(new Error(`Error loading component: ${url}`));
      };
      script.onload = () => {
        // loaded doesn't mean executed. There can still be issues.
        scriptState = 'loaded';
      };
      script.src = url;
      document.head.appendChild(script);
      setTimeout(() => reject(new Error(`Timeout loading component after ${ms}ms: ${url} ${scriptState}`)), ms);
    } catch (e) {
      reject(e);
    }
  });
}

const decoratedLoadURL = compose(
  retry({ times: 3 }),
  deferUntilOnline(),
)(loadURL);

function waitForComponent(name) {
  return new Promise((resolve, reject) => {
    try {
      asyncDefine([`MML-${name}`], (component) => {
        if (component instanceof Object && 'default' in component) {
          return resolve(component.default);
        }
        resolve(component);
      });
    } catch (e) {
      reject(e);
    }
  });
}

export function loadComponent({ name, url, timeout }) {
  return Promise.race([
    decoratedLoadURL(url, timeout),
    waitForComponent(name),
  ]);
}
